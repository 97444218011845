import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    accountSettings: null,
    printerIp: null,
    isTagPrinterEnabled: true,
    loading: false,
    patchAccountSettingLoading: false,
    patchPrinterIpLoading: false,
    errorMessage: null,
    patchAccountSettingError: false,
    patchPrinterIpError: false,
    error: false
};

const accountStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const accountFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    })
}

const fetchPrinterIpSuccess = (state, action) => {
    return updateObject(state, {
        printerIp: action.data.printer_ips,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const fetchAccountSettingsSuccess = (state, action) => {
    return updateObject(state, {
        accountSettings: action.data.account_settings,
        isTagPrinterEnabled: action.data.account_settings.tag_printer_enabled,
        patchAccountSettingLoading: false,
        patchAccountSettingError: false,
        errorMessage: null
    })
}

const patchAccountSettingsStart = (state, action) => {
    return updateObject(state, {
        patchAccountSettingLoading: true
    })
}

const patchAccountSettingsFail = (state, action) => {
    return updateObject(state, {
        patchAccountSettingLoading: false,
        patchAccountSettingError: true,
        errorMessage: action.errorMessage
    })
}

const patchAccountSettingsSuccess = (state, action) => {
    return updateObject(state, {
        patchAccountSettingLoading: false,
        patchAccountSettingError: false,
        errorMessage: null
    })
}

const patchPrinterIpStart = (state, action) => {
    return updateObject(state, {
        patchPrinterIpLoading: true
    })
}

const patchPrinterIpFail = (state, action) => {
    return updateObject(state, {
        patchPrinterIpLoading: false,
        patchPrinterIpError: true,
        errorMessage: action.errorMessage
    })
}


const patchPrinterIpSuccess = (state, action) => {
    return updateObject(state, {
        patchPrinterIpLoading: false,
        patchPrinterIpError: false,
        errorMessage: null
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_SETTINGS_START: return accountStart(state, action);
        case actionTypes.FETCH_ACCOUNT_SETTINGS_SUCCESS: return fetchAccountSettingsSuccess(state, action);
        case actionTypes.FETCH_ACCOUNT_SETTINGS_FAIL: return accountFail(state, action);

        case actionTypes.PATCH_ACCOUNT_SETTINGS_START: return patchAccountSettingsStart(state, action);
        case actionTypes.PATCH_ACCOUNT_SETTINGS_SUCCESS: return patchAccountSettingsSuccess(state, action);
        case actionTypes.PATCH_ACCOUNT_SETTINGS_FAIL: return patchAccountSettingsFail(state, action);

        case actionTypes.FETCH_PRINTER_IP_START: return accountStart(state, action);
        case actionTypes.FETCH_PRINTER_IP_SUCCESS: return fetchPrinterIpSuccess(state, action);
        case actionTypes.FETCH_PRINTER_IP_FAIL: return accountFail(state, action);

        case actionTypes.PATCH_PRINTER_IP_START: return patchPrinterIpStart(state, action);
        case actionTypes.PATCH_PRINTER_IP_SUCCESS: return patchPrinterIpSuccess(state, action);
        case actionTypes.PATCH_PRINTER_IP_FAIL: return patchPrinterIpFail(state, action);

        default:
            return state;
    }
};

export default reducer;