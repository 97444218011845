export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const addYearToDate = (date, years) => {
    return new Date(date.setFullYear(date.getFullYear() + years));
}

export const getYYYYMMDDFormat = (date) => {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const getYYYYMMFormat = (date) => {
    let month = '' + (date.getMonth() + 1),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;

    return [year, month].join('-');
}

export const getPreviousMonday = (date = null,) => {
    const prevMonday = date && new Date(date.valueOf()) || new Date()
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7)
    return prevMonday
}

export const getSubtractedDate = (date, days) => {
    let startdate = new Date();
    startdate = new Date(startdate);
    let newDate = new Date();
    newDate.setDate(date.getDate() - days);
    return newDate;
}