import axios from 'axios';

import * as actionTypes from './actionTypes';

export const analyticsStart = (type) => {
    return {
        type: type
    }
};

export const analyticsFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    }
};

export const analyticsSuccess = (data, type) => {
    return {
        type: type,
        data: data
    }
};

export const fetchMonthlySalesAnnum = (token, yearOne, yearTwo, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/salesDataYearVsYear?yearOne=" + yearOne + "&yearTwo=" + yearTwo + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_FAIL));
            });
    }
};

export const fetchDailySales = (token, date, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_DAILY_SALES_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/dailySales?date=" + date + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_DAILY_SALES_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_DAILY_SALES_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_DAILY_SALES_FAIL));
            });
    }
};

export const fetchMonthlySales = (token, date, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_MONTHLY_SALES_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/monthlySales?date=" + date + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_MONTHLY_SALES_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_MONTHLY_SALES_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_MONTHLY_SALES_FAIL));
            });
    }
};

export const fetchYearlySales = (token, date, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_YEARLY_SALES_START));
        const header = {
            headers: {
                Authorization: token
            }
        }

        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/yearlySales?date=" + date + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_YEARLY_SALES_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_YEARLY_SALES_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_YEARLY_SALES_FAIL));
            });
    }
};

export const fetchDailyPercentage = (token, periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_DAILY_SALES_PERCENTAGE_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/SalesCompare?periodOneStart=" + periodOneStart + "&periodOneEnd=" + periodOneEnd + "&periodTwoStart=" + periodTwoStart + "&periodTwoEnd=" + periodTwoEnd + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_YEARLY_SALES_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_DAILY_SALES_PERCENTAGE_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_DAILY_SALES_PERCENTAGE_FAIL));
            });
    }
};

export const fetchWeeklyPercentage = (token, periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd, status, filterType = null) => {
    return dispatch => {
        dispatch(analyticsStart(actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let urlStatus = "";
        if (status != null)
            urlStatus = "&status=" + status;
        let urlFilterType = "";
        if (filterType != null)
            urlFilterType = "&filterType=" + filterType;
        const url = "/cleaners/SalesCompare?periodOneStart=" + periodOneStart + "&periodOneEnd=" + periodOneEnd + "&periodTwoStart=" + periodTwoStart + "&periodTwoEnd=" + periodTwoEnd + urlStatus + urlFilterType;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(analyticsFail(response.data.status_message, actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_FAIL));
                } else {
                    dispatch(analyticsSuccess(response.data, actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_SUCCESS));
                }
            }).catch(err => {
                dispatch(analyticsFail(err, actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_FAIL));
            });
    }
};