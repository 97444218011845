import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import NavigationItems from './NavigationItems';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import AppToolbarStyles from './AppToolbarStyles';

const AppToolbar = (props) => {
    const classes = AppToolbarStyles();
    const isAuthenticated = useSelector(state => state.auth.token == null ? false : true);
    const companyName = localStorage.getItem('companyName');

    return (
        <div className={classes.mainBody}>
            <div className={classes.toolBar} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography className={classes.Typography} variant="h6" noWrap component={Link} to="/">
                            {companyName ? "Welcome " + companyName :
                                <div>
                                    <span className={classes.companyName}>Posper </span>a simple dry cleaning order management tool
                                </div>}
                        </Typography>
                        <NavigationItems isAuthenticated={isAuthenticated} styleName={classes.appbarItems} />
                    </Toolbar>
                </AppBar>
            </div>
            <div className={classes.main}>
                {props.main}
            </div>
        </div >
    );
}

export default AppToolbar;