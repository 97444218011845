import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
    appDrawer: {
        display: "flex",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: "relative"
    },
    drawerOpen: {
        backgroundColor: "#233044",
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        backgroundColor: "#233044",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'none',
        paddingLeft: theme.spacing(1),
    },
    contentRoot: {
        display: "flex",
        flexGrow: "2",
        justifyContent: "center"
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(4),
        cursor: "pointer"
    },
    icon: {
        color: "white"
    },
    divider: {
        backgroundColor: "white"
    }
}));

export default useStyles;