import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../store/actions/index';

import { Button, Typography } from '@material-ui/core';

const NavigationItems = (props) => {
    const rememberMe = useSelector(state => state.auth.rememberMe);
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(actions.logout(rememberMe));
    }

    return (
        <React.Fragment>
            <div className={props.styleName}>
                {props.isAuthenticated ? <Button color="inherit" component={Link} to="/login" onClick={logoutHandler} style={{ textTransform: "none" }}>
                    <Typography noWrap variant="h6">
                        Logout
                    </Typography>
                </Button> : null}
            </div>
        </React.Fragment>);

};

export default NavigationItems;