import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Layout from './Layout/Layout';
import * as actions from './store/actions/index';

const Login = React.lazy(() => {
  return import('./containers/Login/Login');
});

const SignUp = React.lazy(() => {
  return import('./containers/SignUp/SignUp');
})

const Home = React.lazy(() => {
  return import('./containers/Home/Home');
});

const NewOrder = React.lazy(() => {
  return import('./containers/NewOrder/NewOrder');
});

const Pickups = React.lazy(() => {
  return import('./containers/Pickups/Pickups');
});

const ItemList = React.lazy(() => {
  return import('./containers/ItemList/itemList');
});

const CustomerList = React.lazy(() => {
  return import('./containers/CustomerList/CustomerList');
});

const ModifyOrder = React.lazy(() => {
  return import('./containers/ModifyOrder/ModifyOrder');
});

const OrderHistory = React.lazy(() => {
  return import('./containers/OrderHistory/OrderHistory');
});

const Analytics = React.lazy(() => {
  return import('./containers/Analytics/Analytics');
});

const AccountSettings = React.lazy(() => {
  return import('./containers/AccountSettings/AccountSettings');
});

const App = () => {
  const isAuthenticated = useSelector(state => state.auth.token == null ? false : true);
  const rememberMe = useSelector(state => state.auth.rememberMe);
  const dispatch = useDispatch();

  const onTryAutoSignup = () => dispatch(actions.authCheckState(rememberMe));

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let routes = (
    <Switch>
      <Route path="/login" render={prop => <Login {...prop} />} />
      <Route path="/sign-up" render={prop => <SignUp {...prop} />} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/login" render={props => <Login {...props} />} />
        <Route path="/new-order" render={props => <NewOrder {...props} />} />
        <Route path="/item-list" render={props => <ItemList {...props} />} />
        <Route path="/pickups" render={props => <Pickups {...props} />} />
        <Route path="/customer-list" render={props => <CustomerList {...props} />} />
        <Route path="/modify-orders" render={props => <ModifyOrder {...props} />} />
        <Route path="/order-history" render={props => <OrderHistory {...props} />} />
        <Route path="/analytics" render={props => <Analytics {...props} />} />
        <Route path="/account-settings" render={props => <AccountSettings {...props} />} />
        <Route path="/sign-up" render={props => <SignUp {...props} />} />
        <Route path="/" exact render={props => <Home {...props} />} />
      </Switch>
    );
  }

  return (
    <React.Fragment>
      <Layout>
        <Suspense fallback={<p>...loading</p>}>{routes}</Suspense>
      </Layout>
    </React.Fragment>
  );
}

export default withRouter(App);
