import axios from 'axios';

import * as actionTypes from './actionTypes';

export const customerStart = (type) => {
    return {
        type: type
    }
};

export const customerFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    }
};

export const customerSuccess = (data, type) => {
    return {
        type: type,
        data: data
    }
};

export const fetchAllCustomers = (token) => {
    return dispatch => {
        dispatch(customerStart(actionTypes.FETCH_CUSTOMER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/customers";
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(customerFail(response.data.status_message, actionTypes.FETCH_CUSTOMER_FAIL));
                } else {
                    dispatch(customerSuccess(response.data, actionTypes.FETCH_CUSTOMER_LIST_SUCCESS))
                }
            }).catch(err => {
                dispatch(customerFail(err, actionTypes.FETCH_CUSTOMER_FAIL));
            });
    }
};

export const postCustomers = (token, customer) => {
    return dispatch => {
        dispatch(customerStart(actionTypes.POST_CUSTOMER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/customers";
        axios.post(url, customer, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(customerFail(response.data.status_message, actionTypes.POST_CUSTOMER_FAIL));
                } else {
                    dispatch(customerSuccess(null, actionTypes.POST_ORDER_SUCCESS));
                    dispatch(fetchAllCustomers(token));
                }
            }).catch(err => {
                dispatch(customerFail(err, actionTypes.POST_CUSTOMER_FAIL));
            });
    }
};

export const patchCustomers = (token, customer) => {
    return dispatch => {
        dispatch(customerStart(actionTypes.PATCH_CUSTOMER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/customers";
        axios.post(url, customer, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(customerFail(response.data.status_message, actionTypes.PATCH_CUSTOMER_FAIL));
                } else {
                    dispatch(customerSuccess(null, actionTypes.POST_ORDER_SUCCESS));
                    dispatch(fetchAllCustomers(token));
                }
            }).catch(err => {
                dispatch(customerFail(err, actionTypes.PATCH_CUSTOMER_FAIL));
            });
    }
};


export const deleteCustomers = (token, customer) => {
    return dispatch => {
        dispatch(customerStart(actionTypes.DELETE_CUSTOMER_START));
        const url = "/cleaners/customers";
        axios.delete(url, {
            data: customer, headers: {
                "Authorization": token
            }
        }).then(response => {
            if (response.data.status === "FAIL") {
                dispatch(customerFail(response.data.status_message, actionTypes.DELETE_CUSTOMER_FAIL));
            } else {
                dispatch(customerSuccess(null, actionTypes.DELETE_CUSTOMER_SUCCESS));
                dispatch(fetchAllCustomers(token));
            }
        }).catch(err => {
            dispatch(customerFail(err, actionTypes.DELETE_CUSTOMER_FAIL));
        });
    }
};