import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    customerList: {},
    loading: false,
    errorMessage: null,
    error: false
};

const customerStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const customerDeleteSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        errorMessage: null
    })
}

const customerPATCHSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        errorMessage: null
    })
}

const customerPostedSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        errorMessage: null
    })
}

const customerListSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        customerList: action.data.customer_list,
        error: false,
        errorMessage: null
    })
}

const customerFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMER_START: return customerStart(state, action);
        case actionTypes.FETCH_CUSTOMER_LIST_SUCCESS: return customerListSuccess(state, action);
        case actionTypes.FETCH_CUSTOMER_FAIL: return customerFail(state, action);

        case actionTypes.POST_CUSTOMER_START: return customerStart(state, action);
        case actionTypes.POST_CUSTOMER_SUCCESS: return customerPostedSuccess(state, action);
        case actionTypes.POST_CUSTOMER_FAIL: return customerFail(state, action);

        case actionTypes.PATCH_CUSTOMER_START: return customerStart(state, action);
        case actionTypes.PATCH_CUSTOMER_SUCCESS: return customerPATCHSuccess(state, action);
        case actionTypes.PATCH_CUSTOMER_FAIL: return customerFail(state, action);

        case actionTypes.DELETE_CUSTOMER_START: return customerStart(state, action);
        case actionTypes.DELETE_CUSTOMER_SUCCESS: return customerDeleteSuccess(state, action);
        case actionTypes.DELETE_CUSTOMER_FAIL: return customerFail(state, action);

        default:
            return state;
    }
};

export default reducer;