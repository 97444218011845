import axios from 'axios';

import * as actionTypes from './actionTypes';

export const printStart = (type) => {
    return {
        type: type
    }
};

export const printSuccess = () => {
    return {
        type: actionTypes.POST_RECEIPT_SUCCESS,
    }
};

export const printFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    }
};

export const postPrint = (token, order) => {
    return dispatch => {
        dispatch(printStart(actionTypes.POST_RECEIPT_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let url = "/cleaners/print?store_id=1";
        axios.post(url, order, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(printFail(response.data.status_message, actionTypes.POST_RECEIPT_FAIL));
                } else {
                    dispatch(printSuccess())
                }
            }).catch(err => {
                dispatch(printFail(err, actionTypes.POST_RECEIPT_FAIL));
            });
    }
};

export const postPrintTag = (token, order) => {
    return dispatch => {
        dispatch(printStart(actionTypes.POST_RECEIPT_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let url = "/cleaners/print/tag?store_id=1";
        axios.post(url, order, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(printFail(response.data.status_message, actionTypes.POST_RECEIPT_FAIL));
                } else {
                    dispatch(printSuccess())
                }
            }).catch(err => {
                dispatch(printFail(err, actionTypes.POST_RECEIPT_FAIL));
            });
    }
};

export const postPrintRceipt = (token, order) => {
    return dispatch => {
        dispatch(printStart(actionTypes.POST_RECEIPT_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let url = "/cleaners/print/receipt?store_id=1";
        axios.post(url, order, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(printFail(response.data.status_message, actionTypes.POST_RECEIPT_FAIL));
                } else {
                    dispatch(printSuccess())
                }
            }).catch(err => {
                dispatch(printFail(err, actionTypes.POST_RECEIPT_FAIL));
            });
    }
};