import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    itemList: null,
    loading: false,
    errorMessage: null,
    error: false
};

const itemStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
};

const itemSuccess = (state, action) => {
    return updateObject(state, {
        itemList: action.data,
        error: false,
        loading: false
    });
};

const updatedItemSuccess = (state, action) => {
    return updateObject(state, {
        error: false,
        loading: false
    });
};


const newItemSuccess = (state, action) => {
    return updateObject(state, {
        error: false,
        loading: false
    });
};

const deletedItemSuccess = (state, action) => {
    return updateObject(state, {
        error: false,
        loading: false
    });
};

const itemFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ITEM_LIST_START: return itemStart(state, action);
        case actionTypes.FETCH_ITEM_LIST_SUCCESS: return itemSuccess(state, action);
        case actionTypes.FETCH_ITEM_LIST_FAIL: return itemFail(state, action);

        case actionTypes.PATCH_ITEM_LIST_START: return itemStart(state, action);
        case actionTypes.PATCH_ITEM_LIST_FAIL: return itemFail(state, action);
        case actionTypes.PATCH_ITEM_LIST_SUCESS: return updatedItemSuccess(state, action);

        case actionTypes.POST_ITEM_LIST_START: return itemStart(state, action);
        case actionTypes.POST_ITEM_LIST_FAIL: return itemFail(state, action);
        case actionTypes.POST_ITEM_LIST_SUCESS: return newItemSuccess(state, action);

        case actionTypes.DELETE_ITEM_LIST_START: return itemStart(state, action);
        case actionTypes.DELETE_ITEM_LIST_FAIL: return itemFail(state, action);
        case actionTypes.DELETE_ITEM_LIST_SUCESS: return deletedItemSuccess(state, action);

        default:
            return state;
    }
};

export default reducer;