import axios from 'axios';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userEmail, companyName, rememberMe) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        Token: token,
        userEmail: userEmail,
        companyName: companyName,
        rememberMe: rememberMe
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};


export const logout = (rememberMe) => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('companyName');
    if (!rememberMe)
        localStorage.removeItem('userEmail');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const authFail = (errorMessage) => {
    return {
        type: actionTypes.AUTH_FAIL,
        errorMessage: errorMessage
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const auth = (email, password, isRemember) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            user_name: email,
            user_password: password
        };
        let url = 'cleaners/user';
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(authFail(response.data.status_message));
                } else {
                    const expirationDate = new Date(new Date().getTime() + response.data.expiration_time);
                    if (isRemember)
                        localStorage.setItem('userEmail', email);
                    localStorage.setItem('token', response.data.jwt);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('companyName', response.data.company_name);
                    dispatch(authSuccess(response.data.jwt, email, response.data.company_name, isRemember));
                    dispatch(checkAuthTimeout(response.data.expiration_time));
                }

            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const authCheckState = (rememberMe) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout(rememberMe));
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout(rememberMe));
            } else {
                const userEmail = localStorage.getItem('userEmail');
                const companyName = localStorage.getItem('companyName');
                dispatch(authSuccess(token, userEmail, companyName, rememberMe));
            }
        }
    };
};

