import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import itemListReducer from './store/reducers/itemlist';
import orderReducer from './store/reducers/order';
import customerReducer from './store/reducers/customer';
import signUpReducer from './store/reducers/signUp';
import reprintReducer from './store/reducers/reprint';
import analyticsReducer from './store/reducers/analytics';
import accountReducer from './store/reducers/account';
import checkoutReducer from './store/reducers/checkout';

axios.defaults.baseURL = 'https://posperqa.ca';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    itemList: itemListReducer,
    order: orderReducer,
    signUp: signUpReducer,
    customer: customerReducer,
    reprint: reprintReducer,
    analytics: analyticsReducer,
    account: accountReducer,
    checkout: checkoutReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
