
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    mainBody: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flexGrow: "2",
    },
    title: {
        flexGrow: 1,
    },
    appbarItems: {
        display: "flex",
        flexGrow: 1.25,
        justifyContent: "flex-end",
        textDecoration: "none",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        maxWidth: "100%",
        position: "static",
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        fontSize: theme.spacing(3),
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    Typography: {
        textDecoration: "none",
        color: "unset",
        fontSize: theme.spacing(3.5),
    },
    toolBar: {
        display: "flex",
        justifyContent: "center"
    },
    main: {
        height: "94vh",
    },
    companyName: {
        color: "#f50057"
    }
}));

export default useStyles;