import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    checkoutItems: [],
    checkoutOrder: {}
};

const updateCheckoutItems = (state, action) => {
    return updateObject(state, {
        checkoutItems: action.checkoutItems,
    });
}

const checkoutTtemReset = (state, action) => {
    return updateObject(state, {
        checkoutItems: [],
    });
};

const updateCheckoutOrder = (state, action) => {
    return updateObject(state, {
        checkoutOrder: action.checkoutOrder
    })
}

const checkoutOrderReset = (state, action) => {
    return updateObject(state, {
        checkoutOrder: {},
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CHECKOUT_ITEMS: return updateCheckoutItems(state, action);
        case actionTypes.CHECKOUT_ITEMS_RESET: return checkoutTtemReset(state, action);

        case actionTypes.UPDATE_CHECKOUT_ORDER: return updateCheckoutOrder(state, action);
        case actionTypes.CHECKOUT_ORDER_RESET: return checkoutOrderReset(state, action);

        default:
            return state;
    }
};

export default reducer;