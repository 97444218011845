import * as actionTypes from './actionTypes';

export const updateCheckoutItems = (checkoutItems) => {
    return {
        type: actionTypes.UPDATE_CHECKOUT_ITEMS,
        checkoutItems: checkoutItems
    }
}

export const checkoutTtemRest = () => {
    return {
        type: actionTypes.CHECKOUT_ITEMS_RESET
    }
};

export const updateCheckoutOrder = (checkoutOrder) => {
    return {
        type: actionTypes.UPDATE_CHECKOUT_ORDER,
        checkoutOrder: checkoutOrder
    }
};

export const checkoutOrderReset = () => {
    return {
        type: actionTypes.CHECKOUT_ORDER_RESET
    }
};