import axios from 'axios';

import * as actionTypes from './actionTypes';

export const accountStart = (type) => {
    return {
        type: type
    }
};

export const accountFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    }
};

export const accountSuccess = (data, type) => {
    return {
        type: type,
        data: data
    }
};

export const fetchAccountSettings = (token) => {
    return dispatch => {
        dispatch(accountStart(actionTypes.FETCH_ACCOUNT_SETTINGS_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/accountSettings?store_id=1"
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(accountFail(response.data.status_message, actionTypes.FETCH_ACCOUNT_SETTINGS_FAIL));
                } else {
                    dispatch(accountSuccess(response.data, actionTypes.FETCH_ACCOUNT_SETTINGS_SUCCESS));
                }
            }).catch(err => {
                dispatch(accountFail(err, actionTypes.FETCH_ACCOUNT_SETTINGS_FAIL));
            });
    }
};

export const fetchPrinterIp = (token) => {
    return dispatch => {
        dispatch(accountStart(actionTypes.FETCH_PRINTER_IP_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/printerIp?store_id=1"
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(accountFail(response.data.status_message, actionTypes.FETCH_PRINTER_IP_FAIL));
                } else {
                    dispatch(accountSuccess(response.data, actionTypes.FETCH_PRINTER_IP_SUCCESS));
                }
            }).catch(err => {
                dispatch(accountFail(err, actionTypes.FETCH_PRINTER_IP_FAIL));
            });
    }
};

export const patchAccountSettings = (token, accountSettings) => {
    return dispatch => {
        dispatch(accountStart(actionTypes.PATCH_ACCOUNT_SETTINGS_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/accountSettings";
        axios.patch(url, accountSettings, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(accountFail(response.data.status_message, actionTypes.PATCH_ACCOUNT_SETTINGS_FAIL));
                } else {
                    dispatch(accountSuccess(response.data, actionTypes.PATCH_ACCOUNT_SETTINGS_SUCCESS))
                }
            }).catch(err => {
                dispatch(accountFail(err, actionTypes.PATCH_ACCOUNT_SETTINGS_FAIL));
            });
    }
};

export const patchPrinterIp = (token, printerIp) => {
    return dispatch => {
        dispatch(accountStart(actionTypes.PATCH_PRINTER_IP_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/printerIp";
        axios.patch(url, printerIp, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(accountFail(response.data.status_message, actionTypes.PATCH_PRINTER_IP_FAIL));
                } else {
                    dispatch(accountSuccess(response.data, actionTypes.PATCH_PRINTER_IP_SUCCESS))
                }
            }).catch(err => {
                dispatch(accountFail(err, actionTypes.PATCH_PRINTER_IP_FAIL));
            });
    }
};