import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    errorMessage: null,
    error: false,
    loading: false,
};

const printStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const printFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    })
}

const printSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        errorMessage: null
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_RECEIPT_START: return printStart(state, action);
        case actionTypes.POST_RECEIPT_SUCCESS: return printSuccess(state, action);
        case actionTypes.POST_RECEIPT_FAIL: return printFail(state, action);
        default:
            return state;
    }
};

export default reducer;