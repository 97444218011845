import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    orderList: [],
    orderListHistory: [],
    orderListCount: 0,
    loading: false,
    errorMessage: null,
    error: false
};

const orderStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const orderSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: false,
        errorMessage: null
    })
}

const fetchOrderListSuccess = (state, action) => {
    if (action.data.customer_orders.length >= 1 && action.data.customer_orders[0].status === "INPROGRESS")
        return updateObject(state, {
            loading: false,
            orderList: action.data.customer_orders,
            orderListCount: action.data.customer_orders.length,
            error: false,
            errorMessage: null
        })
    else
        return updateObject(state, {
            loading: false,
            orderListHistory: action.data.customer_orders,
            error: false,
            errorMessage: null
        })
}

const orderFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    })
}

const orderReset = (state, action) => {
    return updateObject(state, {
        orderList: [],
        loading: false,
        errorMessage: null,
        error: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_ORDER_START: return orderStart(state, action);
        case actionTypes.POST_ORDER_SUCCESS: return orderSuccess(state, action);
        case actionTypes.POST_ORDER_FAIL: return orderFail(state, action);

        case actionTypes.FETCH_ORDER_START: return orderStart(state, action);
        case actionTypes.FETCH_ORDER_LIST_SUCCESS: return fetchOrderListSuccess(state, action);
        case actionTypes.FETCH_ORDER_FAIL: return orderFail(state, action);

        case actionTypes.PATCH_ORDER_START: return orderStart(state, action);
        case actionTypes.PATCH_ORDER_SUCCESS: return orderSuccess(state, action);
        case actionTypes.PATCH_ORDER_FAIL: return orderFail(state, action);

        case actionTypes.ORDER_RESET: return orderReset(state, action);
        default:
            return state;
    }
};

export default reducer;