import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    errorMessage: null,
    error: false,
    loading: false,
};

const signUpStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true, error: false });
};

const signUpSuccess = (state, action) => {
    return updateObject(state, {
        errorMessage: null,
        loading: false,
        error: false
    });
};

const signUpFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false,
        error: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGNUP_START: return signUpStart(state, action);
        case actionTypes.SIGNUP_SUCCESS: return signUpSuccess(state, action);
        case actionTypes.SIGNUP_FAIL: return signUpFail(state, action);
        default:
            return state;
    }
};

export default reducer;