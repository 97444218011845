import axios from 'axios';

import * as actionTypes from './actionTypes';

export const itemStart = (type) => {
    return {
        type: type
    }
};

export const itemFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    };
};

export const itemSuccess = (data, type) => {
    return {
        type: type,
        data: data
    }
}

export const fetchItemList = (token) => {
    return dispatch => {
        dispatch(itemStart(actionTypes.FETCH_ITEM_LIST_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        axios.get('cleaners/items', header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(itemFail(response.data.status_message, actionTypes.FETCH_ITEM_LIST_FAIL));
                } else {
                    let list = response.data.item_list;
                    if (list !== null & list !== undefined) {
                        list.sort(function (a, b) {
                            var textA = a.item_name.toUpperCase();
                            var textB = b.item_name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                    }
                    dispatch(itemSuccess(list, actionTypes.FETCH_ITEM_LIST_SUCCESS))
                }
            }).catch(err => {
                dispatch(itemFail(err, actionTypes.FETCH_ITEM_LIST_FAIL));
            });
    }
}

export const patchItemList = (token, updateItemList) => {
    return dispatch => {
        itemStart(actionTypes.PATCH_ITEM_LIST_START)
        const header = {
            headers: {
                Authorization: token
            }
        }
        axios.patch('cleaners/items', updateItemList, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(itemFail(response.data.status_message, actionTypes.PATCH_ITEM_LIST_FAIL));
                } else {
                    dispatch(itemSuccess(null, actionTypes.PATCH_ITEM_LIST_SUCESS));
                    dispatch(fetchItemList(token));
                }
            }).catch(err => {
                dispatch(itemFail(err, actionTypes.PATCH_ITEM_LIST_FAIL));
            });
    }
}

export const postItemList = (token, newItemList) => {
    return dispatch => {
        dispatch(itemStart(actionTypes.POST_ITEM_LIST_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        axios.post('cleaners/items', newItemList, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(itemFail(response.data.status_message, actionTypes.POST_ITEM_LIST_FAIL));
                } else {
                    dispatch(itemSuccess(null, actionTypes.POST_ITEM_LIST_SUCESS));
                    dispatch(fetchItemList(token));
                }
            }).catch(err => {
                dispatch(itemFail(err, actionTypes.POST_ITEM_LIST_FAIL));
            });
    }
}

export const deleteItemList = (token, deletedItemList) => {
    return dispatch => {
        dispatch(itemStart(actionTypes.DELETE_ITEM_LIST_START));
        axios.delete('cleaners/items', {
            data: deletedItemList, headers: {
                "Authorization": token
            }
        }).then(response => {
            if (response.data.status === "FAIL") {
                dispatch(itemFail(response.data.status_message, actionTypes.DELETE_ITEM_LIST_FAIL));
            } else {
                dispatch(itemSuccess(null, actionTypes.DELETE_ITEM_LIST_SUCESS));
                dispatch(fetchItemList(token));
            }
        }).catch(err => {
            dispatch(itemFail(err, actionTypes.DELETE_ITEM_LIST_FAIL));
        });
    }
}
