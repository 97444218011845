import axios from 'axios';

import * as action from './index';
import * as actionTypes from './actionTypes';

export const orderStart = (type) => {
    return {
        type: type
    }
};

export const orderFail = (errorMessage, type) => {
    return {
        type: type,
        errorMessage: errorMessage
    }
};

export const orderSuccess = (data, type) => {
    return {
        type: type,
        data: data
    }
};

export const resetOrder = () => {
    return {
        type: actionTypes.ORDER_RESET
    }
};

export const fetchOrders = (token, status) => {
    return dispatch => {
        dispatch(orderStart(actionTypes.FETCH_ORDER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/orders?status=" + status;
        axios.get(url, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(orderFail(response.data.status_message, actionTypes.FETCH_ORDER_FAIL));
                } else {
                    dispatch(orderSuccess(response.data, actionTypes.FETCH_ORDER_LIST_SUCCESS));
                }
            }).catch(err => {
                dispatch(orderFail(err, actionTypes.FETCH_ORDER_FAIL));
            });
    }
};

export const postCustomerOrder = (token, order, email) => {
    return dispatch => {
        dispatch(orderStart(actionTypes.POST_ORDER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        let url = "/cleaners/order";
        if (email)
            url = "/cleaners/order?emailReceipt=" + email;
        axios.post(url, order, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(orderFail(response.data.status_message, actionTypes.POST_ORDER_FAIL));
                } else {
                    dispatch(orderSuccess(null, actionTypes.POST_ORDER_SUCCESS));
                    dispatch(action.postPrint(token, response.data.order));
                    dispatch(action.updateCheckoutItems([]));
                }
            }).catch(err => {
                dispatch(orderFail(err, actionTypes.POST_ORDER_FAIL));
            });
    }
};

export const patchCustomerOrder = (token, order) => {
    return dispatch => {
        dispatch(orderStart(actionTypes.PATCH_ORDER_START));
        const header = {
            headers: {
                Authorization: token
            }
        }
        const url = "/cleaners/order";
        axios.patch(url, order, header)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(orderFail(response.data.status_message, actionTypes.PATCH_ORDER_FAIL));
                } else {
                    dispatch(orderSuccess(null, actionTypes.POST_ORDER_SUCCESS))
                }
            }).catch(err => {
                dispatch(orderFail(err, actionTypes.PATCH_ORDER_FAIL));
            });
    }
};