import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    userEmail: null,
    companyName: null,
    rememberMe: false,
    errorMessage: null,
    error: false,
    loading: false,
    authRedirectPath: '/',
};

const authStart = (state, action) => {
    return updateObject(state, { errorMessage: null, loading: true, error: false });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.Token,
        userEmail: action.userEmail,
        companyName: action.companyName,
        rememberMe: action.rememberMe,
        errorMessage: null,
        loading: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.errorMessage,
        loading: false,
        error: true
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userEmail: null,
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        default:
            return state;
    }
};

export default reducer;