
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        display: "flex",
        backgroundColor: "#f7f9fc",
    }
}));

export default useStyles;