export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const FETCH_ITEM_LIST_START = 'FETCH_ITEM_LIST_START';
export const FETCH_ITEM_LIST_SUCCESS = 'FETCH_ITEM_LIST_SUCCESS';
export const FETCH_ITEM_LIST_FAIL = 'FETCH_ITEM_LIST_FAIL';

export const PATCH_ITEM_LIST_START = 'PATCH_ITEM_LIST_START';
export const PATCH_ITEM_LIST_FAIL = 'PATCH_ITEM_LIST_FAIL';
export const PATCH_ITEM_LIST_SUCESS = 'PATCH_ITEM_LIST_SUCESS';

export const UPDATE_CHECKOUT_ITEMS = "UPDATE_CHECKOUT_ITEMS";
export const CHECKOUT_ITEMS_RESET = "CHECKOUT_ITEMS_RESET";

export const POST_ITEM_LIST_START = 'POST_ITEM_LIST_START';
export const POST_ITEM_LIST_FAIL = 'POST_ITEM_LIST_FAIL';
export const POST_ITEM_LIST_SUCESS = 'POST_ITEM_LIST_SUCESS';

export const DELETE_ITEM_LIST_START = 'DELETE_ITEM_LIST_START';
export const DELETE_ITEM_LIST_FAIL = 'DELETE_ITEM_LIST_FAIL';
export const DELETE_ITEM_LIST_SUCESS = 'DELETE_ITEM_LIST_SUCESS';

export const FETCH_CUSTOMER_START = 'FETCH_CUSTOMER_START';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_LIST_SUCCESS = 'FETCH_CUSTOMER_LIST_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';

export const UPDATE_CHECKOUT_ORDER = 'UPDATE_CHECKOUT_ORDER';
export const CHECKOUT_ORDER_RESET = 'CHECKOUT_ORDER_RESET';

export const POST_CUSTOMER_START = 'POST_CUSTOMER_START';
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS';
export const POST_CUSTOMER_FAIL = 'POST_CUSTOMER_FAIL';

export const PATCH_CUSTOMER_START = 'PATCH_CUSTOMER_START';
export const PATCH_CUSTOMER_SUCCESS = 'PATCH_CUSTOMER_SUCCESS';
export const PATCH_CUSTOMER_FAIL = 'PATCH_CUSTOMER_FAIL';

export const DELETE_CUSTOMER_START = 'DELETE_CUSTOMER_START';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL';

export const POST_ORDER_START = 'POST_ORDER_START';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL';

export const FETCH_ORDER_START = "FETCH_ORDER_START";
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL';
export const ORDER_RESET = 'ORDER_RESET';

export const PATCH_ORDER_START = "PATCH_ORDER_START";
export const PATCH_ORDER_SUCCESS = "PATCH_ORDER_SUCCESS";
export const PATCH_ORDER_FAIL = "PATCH_ORDER_FAIL";

export const POST_RECEIPT_START = 'POST_RECEIPT_START';
export const POST_RECEIPT_SUCCESS = 'POST_RECEIPT_SUCCESS';
export const POST_RECEIPT_FAIL = 'POST_RECEIPT_FAIL';

export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';

export const FETCH_SALES_DATE_YEAR_VS_YEAR_START = 'FETCH_SALES_DATE_YEAR_VS_YEAR_START';
export const FETCH_SALES_DATE_YEAR_VS_YEAR_SUCCESS = 'FETCH_SALES_DATE_YEAR_VS_YEAR_SUCCESS';
export const FETCH_SALES_DATE_YEAR_VS_YEAR_FAIL = 'FETCH_SALES_DATE_YEAR_VS_YEAR_FAIL';

export const FETCH_DAILY_SALES_START = 'FETCH_DAILY_SALES_START';
export const FETCH_DAILY_SALES_SUCCESS = 'FETCH_DAILY_SALES_SUCCESS';
export const FETCH_DAILY_SALES_FAIL = 'FETCH_DAILY_SALES_FAIL';

export const FETCH_MONTHLY_SALES_START = 'FETCH_MONTHLY_SALES_START';
export const FETCH_MONTHLY_SALES_SUCCESS = 'FETCH_MONTHLY_SALES_SUCCESS';
export const FETCH_MONTHLY_SALES_FAIL = 'FETCH_MONTHLY_SALES_FAIL';

export const FETCH_YEARLY_SALES_START = 'FETCH_YEARLY_SALES_START';
export const FETCH_YEARLY_SALES_SUCCESS = 'FETCH_YEARLY_SALES_SUCCESS';
export const FETCH_YEARLY_SALES_FAIL = 'FETCH_YEARLY_SALES_FAIL';

export const FETCH_DAILY_SALES_PERCENTAGE_START = 'FETCH_DAILY_SALES_PERCENTAGE_START';
export const FETCH_DAILY_SALES_PERCENTAGE_SUCCESS = 'FETCH_DAILY_SALES_PERCENTAGE_SUCCESS';
export const FETCH_DAILY_SALES_PERCENTAGE_FAIL = 'FETCH_DAILY_SALES_PERCENTAGE_FAIL';

export const FETCH_WEEKLY_SALES_PERCENTAGE_START = 'FETCH_WEEKLY_SALES_PERCENTAGE_START';
export const FETCH_WEEKLY_SALES_PERCENTAGE_SUCCESS = 'FETCH_WEEKLY_SALES_PERCENTAGE_SUCCESS';
export const FETCH_WEEKLY_SALES_PERCENTAGE_FAIL = 'FETCH_WEEKLY_SALES_PERCENTAGE_FAIL';

export const FETCH_ACCOUNT_SETTINGS_START = 'FETCH_ACCOUNT_SETTINGS_START';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_FAIL = 'FETCH_ACCOUNT_SETTINGS_FAIL';

export const PATCH_ACCOUNT_SETTINGS_START = 'PATCH_ACCOUNT_SETTINGS_START';
export const PATCH_ACCOUNT_SETTINGS_SUCCESS = 'PATCH_ACCOUNT_SETTINGS_SUCCESS';
export const PATCH_ACCOUNT_SETTINGS_FAIL = 'PATCH_ACCOUNT_SETTINGS_FAIL';

export const FETCH_PRINTER_IP_START = 'FETCH_PRINTER_IP_START';
export const FETCH_PRINTER_IP_SUCCESS = 'FETCH_PRINTER_IP_SUCCESS';
export const FETCH_PRINTER_IP_FAIL = 'FETCH_PRINTER_IP_FAIL';

export const PATCH_PRINTER_IP_START = 'PATCH_PRINTER_IP_START';
export const PATCH_PRINTER_IP_SUCCESS = 'PATCH_PRINTER_IP_SUCCESS';
export const PATCH_PRINTER_IP_FAIL = 'PATCH_PRINTER_IP_FAIL';