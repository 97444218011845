import React from 'react';
import { useSelector } from 'react-redux';

import AppToolbar from '../components/Navigation/AppToolbar';
import AppDrawer from '../components/Navigation/AppDrawer';
import LayoutStyles from './LayoutStyles';

const Layout = props => {
    const classes = LayoutStyles();
    const isAuthenticated = useSelector(state => state.auth.token == null ? false : true);
    return (
        <div className={classes.layout}>
            {isAuthenticated ? <AppDrawer /> : null}
            <AppToolbar main={props.children} />
        </div>
    );
};

export default Layout;