import axios from 'axios';

import * as actionTypes from './actionTypes';

export const signUpStart = () => {
    return {
        type: actionTypes.SIGNUP_START
    };
};

export const signUpSuccess = (token, userId) => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
        Token: token,
        userId: userId
    };
};

export const signUpFail = (errorMessage) => {
    return {
        type: actionTypes.SIGNUP_FAIL,
        errorMessage: errorMessage
    };
};

export const signUp = (data) => {
    return dispatch => {
        dispatch(signUpStart());


        let url = 'cleaners/company';
        axios.post(url, data)
            .then(response => {
                if (response.data.status === "FAIL") {
                    dispatch(signUpFail(response.data.status_message));
                } else {
                    dispatch(signUpSuccess())
                }
            })
            .catch(err => {
                dispatch(signUpFail(err));
            });
    };
};