import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    monthlySalesList: null,
    dailySales: null,
    dailySalesPercentage: null,
    weeklySalesPercentage: null,
    monthlySales: null,
    monthlySalesData: null,
    yearlySales: null,
    yearlySalesData: null,
    yearlyOrderCount: null,
    loading: false,
    errorMessage: null,
    error: false
};

const analyticsStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
}

const salesDataYearVsYearSuccess = (state, action) => {
    return updateObject(state, {
        monthlySalesList: action.data.monthly_sales_list,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const dailySalesSuccess = (state, action) => {
    return updateObject(state, {
        dailySales: action.data.total_sales,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const monthlySalesSuccess = (state, action) => {
    return updateObject(state, {
        monthlySales: action.data.total_sales,
        monthlySalesData: action.data.sales_data_list,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const yearlySalesSuccess = (state, action) => {
    return updateObject(state, {
        yearlySales: action.data.total_sales,
        yearlySalesData: action.data.sales_data_list,
        yearlyOrderCount: action.data.order_count,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const dailySalesPercentageSuccess = (state, action) => {
    return updateObject(state, {
        dailySalesPercentage: action.data.change_percent,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const weeklySalesPercentageSuccess = (state, action) => {
    return updateObject(state, {
        weeklySalesPercentage: action.data.change_percent,
        loading: false,
        error: false,
        errorMessage: null
    })
}

const analyticsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: action.errorMessage
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_START: return analyticsStart(state, action);
        case actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_SUCCESS: return salesDataYearVsYearSuccess(state, action);
        case actionTypes.FETCH_SALES_DATE_YEAR_VS_YEAR_FAIL: return analyticsFail(state, action);

        case actionTypes.FETCH_DAILY_SALES_START: return analyticsStart(state, action);
        case actionTypes.FETCH_DAILY_SALES_SUCCESS: return dailySalesSuccess(state, action);
        case actionTypes.FETCH_DAILY_SALES_FAIL: return analyticsFail(state, action);

        case actionTypes.FETCH_MONTHLY_SALES_START: return analyticsStart(state, action);
        case actionTypes.FETCH_MONTHLY_SALES_SUCCESS: return monthlySalesSuccess(state, action);
        case actionTypes.FETCH_MONTHLY_SALES_FAIL: return analyticsFail(state, action);

        case actionTypes.FETCH_YEARLY_SALES_START: return analyticsStart(state, action);
        case actionTypes.FETCH_YEARLY_SALES_SUCCESS: return yearlySalesSuccess(state, action);
        case actionTypes.FETCH_YEARLY_SALES_FAIL: return analyticsFail(state, action);

        case actionTypes.FETCH_DAILY_SALES_PERCENTAGE_START: return analyticsStart(state, action);
        case actionTypes.FETCH_DAILY_SALES_PERCENTAGE_SUCCESS: return dailySalesPercentageSuccess(state, action);
        case actionTypes.FETCH_DAILY_SALES_PERCENTAGE_FAIL: return analyticsFail(state, action);

        case actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_START: return analyticsStart(state, action);
        case actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_SUCCESS: return weeklySalesPercentageSuccess(state, action);
        case actionTypes.FETCH_WEEKLY_SALES_PERCENTAGE_FAIL: return analyticsFail(state, action);

        default:
            return state;
    }
};

export default reducer;