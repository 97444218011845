import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";

import { Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Group, CardMembership, Settings, Add, Unarchive, ShowChart, FormatListNumbered, GroupAdd, Edit, ChevronRight, ChevronLeft, History, AssignmentTurnedIn, DoneOutline, DoneAll } from '@material-ui/icons';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import clsx from 'clsx';
import AppDrawerStyles from './AppDrawerStyles';
import Logo from '../../assets/logo.png';

const AppDrawer = () => {
    const classes = AppDrawerStyles();
    const [open, setOpen] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const [pathName, setPathName] = useState("");

    const iconColor = "springgreen";

    useEffect(() => {
        setRedirectHome(false);
    }, [redirectHome]);

    useEffect(() => {
        setPathName(window.location.pathname);
    })

    return (
        <div className={classes.appDrawer}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                <div className={classes.logo} >
                    <img src={Logo} width="60" height="62" onClick={() => setRedirectHome(prevState => !prevState)} />
                </div>
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem button key={"New Order Id"} component={Link} to="/new-order">
                            <Tooltip title="New Order" placement="right">
                                <ListItemIcon style={{ color: pathName === "/new-order" ? iconColor : "white" }}>{<Add />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"New order"} />
                        </ListItem>
                        <ListItem button key={"Pick Ups Id"} component={Link} to="/pickups">
                            <Tooltip title="Pick Ups" placement="right">
                                <ListItemIcon style={{ color: pathName === "/pickups" ? iconColor : "white" }}>{<DoneOutline />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Pick Ups"} />
                        </ListItem>
                        <ListItem button key={"Modify Id"} component={Link} to="/modify-orders">
                            <Tooltip title="Change Orders" placement="right">
                                <ListItemIcon style={{ color: pathName === "/modify-orders" ? iconColor : "white" }}>{<Edit />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Change Orders"} />
                        </ListItem>
                        <ListItem button key={"Order History Id"} component={Link} to="/order-history">
                            <Tooltip title="Order History" placement="right">
                                <ListItemIcon style={{ color: pathName === "/order-history" ? iconColor : "white" }}>{<History />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Order History"} />
                        </ListItem>
                        <ListItem button key={"Analytics Id"} component={Link} to="/analytics">
                            <Tooltip title="Analytics" placement="right">
                                <ListItemIcon style={{ color: pathName === "/analytics" ? iconColor : "white" }}>{<ShowChart />}</ListItemIcon >
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Analytics"} />
                        </ListItem>
                        <ListItem button key={"Item List Id"} component={Link} to="/item-list">
                            <Tooltip title="Item List" placement="right">
                                <ListItemIcon style={{ color: pathName === "/item-list" ? iconColor : "white" }}>{<FormatListNumbered />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Item List"} />
                        </ListItem>
                        <ListItem button key={"Customers List id"} component={Link} to="/customer-list">
                            <Tooltip title="Customers List" placement="right">
                                <ListItemIcon style={{ color: pathName === "/customer-list" ? iconColor : "white" }}>{<Group />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Customers List"} />
                        </ListItem>
                    </List>
                    <Divider className={classes.divider} />
                    <List>
                        <ListItem button key={"managed user Id"}>
                            <Tooltip title="Manage Users" placement="right">
                                <ListItemIcon className={classes.icon}>{<GroupAdd />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Manage Users"} />
                        </ListItem>
                        <ListItem button key={"Pay Subscription Id"}>
                            <Tooltip title="Pay Subscription" placement="right">
                                <ListItemIcon className={classes.icon}>{<CardMembership />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Pay Subscription"} />
                        </ListItem>
                        <ListItem button key={"Account Settings Id"} component={Link} to="/account-settings">
                            <Tooltip title="Account Settings" placement="right">
                                <ListItemIcon style={{ color: pathName === "/account-settings" ? iconColor : "white" }}>{<Settings />}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.icon} primary={"Account Settings"} />
                        </ListItem>
                        <ListItem button key={"Hide Side Menu Id"}>
                            <ListItemIcon className={classes.icon} onClick={event => setOpen(prevState => !prevState)}>
                                {open ? <ChevronLeft /> : <ChevronRight />}
                            </ListItemIcon>
                            <ListItemText className={classes.icon} onClick={event => setOpen(prevState => !prevState)} primary={"Hide Side Menu"} />
                        </ListItem>
                    </List>
                </div>
                {redirectHome ? <Redirect to="/" /> : null}
            </Drawer>
        </div>
    );
}

export default AppDrawer;